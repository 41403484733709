import DatePicker from 'vue2-datepicker'
import VueTypes from 'vue-types'
import { mixin as clickaway } from 'vue-clickaway'
import 'vue2-datepicker/index.css'

export default {
  mixins: [clickaway],
  props: {
    loading: Boolean,
    notAfterToday: Boolean,
    notBeforeToday: Boolean,
    value: VueTypes.any,
    errorMsg: String,
    type: String,
    disabled: Boolean,
    readonly: Boolean,
    disabledDate: {
      type: Function,
      default: () => false,
    },
    errStatic: {
      type: Boolean,
      default: true
    },
    label: String,
    required: Boolean,
  },
  components: {
    DatePicker
  },
  computed: {
    hasError () {
      return !!this.errorMsg
    },
  },
  methods: {
    onClickAway (el) {
      if (this.$refs.datapicker?.popupVisible) {
        const popup = this.$refs.datapicker.$refs.popup?.$el
        if (!popup.contains(el.target)) {
          this.$emit('closepopup')
        }
      }
    },
    disabledDateAfterToday (date) {
      if (this.disabledDate(date)) return true
      if (this.notAfterToday) {
        return date.getTime() > new Date().getTime()
      }
      if (this.notBeforeToday) {
        return date.getTime() < new Date().getTime()
      }
    },
  },
}
